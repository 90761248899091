import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { ZenGame } from "../../components/ZenGame/ZenGame";

const Zen: React.FC<{ user: any }> = (props: { user: any }) => {
  const user = props.user;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {user ? (
          <ZenGame user={user} />
        ) : (
          <IonLoading isOpen={!user} message={"Loading..."} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Zen;

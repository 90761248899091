import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LevelGame } from "../../components/LevelGame/LevelGame";
import api from "../../utils/apiHelper/apiHelper";
import { getUserInfo } from "../../utils/userHelper/userHelper";

const Level: React.FC<any> = (props: any) => {
  const { level } = useParams<any>();
  const [currLevel, setLevel] = useState<LevelObject | undefined>();
  const [user, setUser] = useState<any>();

  const getLevel = async () => {
    const levelInfo = await api.get(
      `level/getLevel/${level}`,
      props.user.getSignInUserSession().getIdToken().getJwtToken()
    );
    setLevel(levelInfo);
  };

  useEffect(() => {
    async function fetchData() {
      const user = await getUserInfo(
        props.user.username,
        props.user.getSignInUserSession().getIdToken().getJwtToken()
      );
      setUser(user);
    }

    fetchData();
  }, [props.user]);

  useEffect(() => {
    if (level) {
      getLevel();
    }
  }, [level]);

  if (currLevel) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              Level {currLevel.level} - {currLevel.title}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <LevelGame
            level={currLevel}
            cognitoUser={props.user}
            userRecord={user}
          />
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Fetching Level info...</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonLoading isOpen={true} />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Level;

interface LevelObject {
  title: string;
  description: string;
  type: string;
  param: string;
  param2: string;
  level: Number;
}

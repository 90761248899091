import React from "react";
import {
  IonContent,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";

const Help: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Help</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="10">
              <IonText>
                <h2>How to Play</h2>
                <p>
                  The Grand Cross is a game that challenges your 5 letter word
                  vocabulary.
                </p>
                <p>
                  {" "}
                  At any given time you may see your next 3 playable letters.
                  The first letter is the letter you are currently playing. The
                  second letter is the next letter you will play, and the third
                  space is your third letter. Playing the letter in the first
                  space will move the next and third letter up one space in play
                  order and give you a new third letter. Use this insight to
                  plan out words.
                </p>

                <p>
                  Randomly, you can recieve a bomb instead of a letter. A bomb
                  can only be placed on square that has a letter on it
                  currently. This will remove that letter for that sqaure. Use
                  bombs to get rid of pesky letters standing in the way of your
                  words or store them in your letter bank for when you have a
                  change in plans.
                </p>

                <p>
                  The Bank square can be used to store one letter or bomb at a
                  time. To use the bank space, simple tap the square to store
                  your current letter to play or if you have banked letter, tap
                  the bank space to play that letter instead. Banked letters can
                  only be played in empty spaces. Banked bombs can only be used
                  on spaces with a letter in them.
                </p>

                <p>
                  The objective of the game is to make 5 letter words. Though
                  some rules may make goals more or less specfic than that.
                  Words can be made in 3 ways. Horziontally, from left to right.
                  Vertically, from top to bottom. Diagonally from top left, to
                  bottom right. Making a word will clear all the spaces used in
                  that word. It is possible to make multiple words at once. For
                  instance if the last letter you place makes a word in more
                  than one direction, you can get a double or even a triple
                  word! We call these a double or a Grand Cross, respectively!
                </p>

                <p>
                  Your score is kept in the bottom left of the screen. Points
                  are awarded as follows:
                </p>
                <ul>
                  <li>Placing any letter on the board - 50 points</li>
                  <li>Making any one word - 500 points</li>
                  <li>Making any two words - 2500 points</li>
                  <li>Making any three words - 10,000 points</li>
                </ul>

                <p>
                  The timer can be viewed in the bottom right of the screen. It
                  tracks the amount of time you have spent in the current games.
                  Some modes challenges you to play for a certain amount of
                  time. Make a word in specfic timelimit. And some modes compare
                  scores based on how fast you achieve the goal.
                </p>

                <p>That is the basics! Have fun and enjoy the game!</p>

                <p>
                  To learn more about how to play check out{" "}
                  <a href="youtube.com">Grand Cross Youtube</a>
                </p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Help;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonLoading,
  isPlatform,
  IonCard,
} from "@ionic/react";
import {
  AdMob,
  AdOptions,
  AdLoadInfo,
  InterstitialAdPluginEvents,
} from "@capacitor-community/admob";

import { isFasterTime } from "../../utils/timeHelper/timerHelper";
import { useLocation } from "react-router";

const GameOver: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation<locationState>();
  const [dismissedAd, setDismissedAd] = useState(false);
  let adId: string | undefined;
  let gameData = location.state;
  const winConditionText = gameData?.winCondition ? "You Win!" : "You Lost!";

  if (isPlatform("ios")) {
    adId = process.env.REACT_APP_IOS_INTERSTITIAL_AD_ID;
  }

  if (isPlatform("android")) {
    adId = process.env.REACT_APP_ANDROID_INTERSTITIAL_AD_ID;
  }

  async function interstitial(): Promise<void> {
    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      setDismissedAd(true);
      setLoading(false);
    });

    AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
      setDismissedAd(true);
      setLoading(false);
    });

    AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, () => {
      setDismissedAd(true);
      setLoading(false);
    });

    const options: AdOptions = {
      adId: adId || "",
      isTesting: process.env.NODE_ENV === "production" ? false : true,
      // npa: true
    };
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  }

  useEffect(() => {
    if (isPlatform("ios") || isPlatform("android")) {
      interstitial();
    } else {
      setLoading(false);
      setDismissedAd(true);
    }
  }, []);

  if (loading) {
    return (
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLoading isOpen={loading} message={"Loading..."} />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Game Over</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {gameData && dismissedAd ? (
          <IonGrid>
            <IonRow
              style={{ height: "70vh" }}
              className="ion-justify-content-center ion-align-items-center"
            >
              <IonCol
                size="12"
                className="final-score-section ion-text-center ion-align-self-center ion-padding"
              >
                <IonCard>
                  <IonTitle className="ion-text-center">
                    {" "}
                    {winConditionText}{" "}
                  </IonTitle>
                  <h3>
                    You earned {gameData.score} points in{" "}
                    {gameData.time.minutes}:
                    {gameData.time.seconds < 10
                      ? `0${gameData.time.seconds}`
                      : gameData.time.seconds}
                    !
                  </h3>
                  <br />
                  <h3>
                    {gameData.winCondition
                      ? "You did it! Think you can beat your score?"
                      : "You did amazing! Try again?"}
                  </h3>
                </IonCard>
              </IonCol>
              <IonCol
                size="12"
                className="ion-text-center new-high-score-section ion-padding"
                hidden={
                  !gameData.winCondition ||
                  gameData.score > gameData.highScore ||
                  isFasterTime(gameData.time, gameData.prTime)
                }
              >
                <h3 hidden={gameData.score < gameData.highScore}>
                  New high score!
                </h3>
                <h3 hidden={!isFasterTime(gameData.time, gameData.prTime)}>
                  New fastest time!
                </h3>
              </IonCol>
              <IonCol size="12" className="ion-text-center">
                <IonButton routerLink={gameData.redirect}>
                  Play again?
                </IonButton>
                {gameData &&
                gameData.redirect.includes("level") &&
                gameData.winCondition ? (
                  <IonButton routerLink={`/story`}>Next Level</IonButton>
                ) : null}
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonLoading isOpen={loading} message={"Loading..."} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default GameOver;

interface locationState {
  winCondition: boolean;
  score: number;
  highScore: number;
  time: {
    minutes: number;
    seconds: number;
  };
  prTime: {
    minutes: number;
    seconds: number;
  };
  redirect: string;
}

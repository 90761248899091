/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonText,
  useIonToast,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from "@ionic/react";
import { useHistory } from "react-router";

import { LetterHandler } from "../../utils/letterHandler/letterHandler";
import {
  addScore,
  clearSpaces,
  generateStarterLetters,
  isEmpty,
} from "../../utils/gameHelper/gameHelper";
import { ScoreBoard } from "../ScoreBoard/ScoreBoard";
import { LetterBoard } from "../LetterBoard/LetterBoard";
import { GameBoard } from "../GameBoard/GameBoard";

import "./LevelGame.css";
import {
  LetterHelper,
  PointsHelper,
  SurvivalHelper,
  TargetHelper,
  TimeHelper,
  WordHelper,
} from "../../utils/levelHelper/levelHelper";
import { isFasterTime, sameTime } from "../../utils/timeHelper/timerHelper";
import api from "../../utils/apiHelper/apiHelper";
import useSound from "use-sound";

export const LevelGame: React.FC<LevelProps> = (props: LevelProps) => {
  const level = props.level;
  const [placeSFX] = useSound("./assets/GrandCross_letter_sfx.mp3");
  const [badPlaceSFX] = useSound("./assets/GrandCross_wrong_placement_sfx.mp3");
  const [bombSFX] = useSound("./assets/GrandCross_bomb.mp3");
  const [madeWordSFX] = useSound("./assets/GrandCross_made_word.mp3");
  const [doubleSFX] = useSound("./assets/GrandCross_double.mp3");
  const [grandCrossSFX] = useSound("./assets/GrandCross_grandcross.mp3");
  const userRecord = props.userRecord;
  const cognitoUser = props.cognitoUser;
  const token = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();
  const history = useHistory();
  const letterHandler: LetterHandler = new LetterHandler();

  const [currentLetter, setCurrentLetter] = useState("");
  const [nextLetter, setNextLetter] = useState("");
  const [queuedLetter, setQueuedLetter] = useState("");
  const [bankedLetter, setBankedLetter] = useState("");
  const [showPlayableSquares, setPlayableSquares] = useState(false);
  const [showBombableSquares, setBombableSquares] = useState(false);
  const [showBankedSquare, setBankedSquare] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);
  const [winCondition, setWinCondition] = useState(false);
  const [present] = useIonToast();
  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [prTime, setPrTime] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [gameOver, setGameOver] = useState(false);
  const [checkForGameOver, setCheckForGameOver] = useState(false);
  const [board, setBoard] = useState([
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
  ]);

  let spacesToClear: number[][] = [];
  const [gameHelper, setGameHelper] = useState<any>();

  /**
   * Place a letter on the board in the designated position if avaliable for play
   * @param {number} row - The row you would like to place the letter 0 indexed
   * @param {number} col - The column you would like to place the letter 0 indexed
   */
  const placeLetter = (row: number, col: number): void => {
    if (timerStarted === false) {
      setTimerStarted(true);
    }

    let copyBoard = board;

    if (showPlayableSquares) {
      if (board[row][col] !== "") {
        badPlaceSFX();
        return;
      }
      copyBoard[row][col] = bankedLetter;
      placeSFX();
      setBoard(copyBoard);
      setBankedLetter("");
      setPlayableSquares(false);
      checkBoard();
      return;
    }

    if (showBombableSquares) {
      if (board[row][col] === "") {
        badPlaceSFX();
        return;
      }
      copyBoard[row][col] = "";
      bombSFX();
      setBoard(copyBoard);
      if (currentLetter === "bomb") {
        setCurrentLetter(nextLetter);
        setNextLetter(queuedLetter);
        setQueuedLetter(letterHandler.generateRandomLetter());
      } else {
        setBankedLetter("");
        if (currentLetter === "bomb") {
          setBombableSquares(true);
        } else {
          setBombableSquares(false);
        }
        setBankedSquare(false);
      }
      return;
    }

    if (
      (board[row][col] === "" && currentLetter === "bomb") ||
      (board[row][col] !== "" && currentLetter !== "bomb")
    ) {
      badPlaceSFX();
      return;
    } else {
      copyBoard[row][col] = currentLetter === "bomb" ? "" : currentLetter;
      placeSFX();
      setBoard(copyBoard);
      setCurrentLetter(nextLetter);
      setNextLetter(queuedLetter);
      setQueuedLetter(letterHandler.generateRandomLetter());
      checkBoard();
    }
  };

  /**
   * Loop through every horizontal, vertical, and diagonal position looking for a word.
   * Will also check for end of game conditions weather win or loss
   */
  const checkBoard = (): void => {
    let count = 0;
    let numberOfWords = 0;
    let wordsMade = [];
    let currentSpacesToClear: number[][] = [];
    let diagWord = "";

    // Check the board horizontally
    for (let i = 0; i < 5; i++) {
      let word = "";
      for (let j = 0; j < 5; j++) {
        if (board[i][j] !== "") {
          currentSpacesToClear.push([i, j]);
          word += board[i][j];
          if (word.length === 5) {
            if (letterHandler.checkDictionary(word.toLowerCase())) {
              wordsMade.push(word.toLowerCase());
              madeWordSFX();
              present({ message: word, duration: 2000, position: "middle" });
              numberOfWords++;
              for (const index of currentSpacesToClear) {
                if (!spacesToClear.includes(index)) {
                  spacesToClear.push(index);
                }
              }
            }
            currentSpacesToClear = [];
          }
        } else {
          currentSpacesToClear = [];
          break;
        }
      }
    }

    // Check the board vertically
    for (let i = 0; i < 5; i++) {
      let word = "";
      for (let j = 0; j < 5; j++) {
        if (board[j][i] !== "") {
          currentSpacesToClear.push([j, i]);
          word += board[j][i];
          if (word.length === 5) {
            if (letterHandler.checkDictionary(word.toLowerCase())) {
              wordsMade.push(word.toLowerCase());
              madeWordSFX();
              present({ message: word, duration: 2000, position: "middle" });
              numberOfWords++;
              for (const index of currentSpacesToClear) {
                if (!spacesToClear.includes(index)) {
                  spacesToClear.push(index);
                }
              }
            }
            currentSpacesToClear = [];
          }
        } else {
          currentSpacesToClear = [];
          break;
        }
      }
    }

    // Check the boards diagonal row
    for (let i = 0; i < 5; i++) {
      if (board[i][i] !== "") {
        currentSpacesToClear.push([i, i]);
        diagWord += board[i][i];
        if (diagWord.length === 5) {
          if (letterHandler.checkDictionary(diagWord.toLowerCase())) {
            wordsMade.push(diagWord.toLowerCase());
            madeWordSFX();
            present({ message: diagWord, duration: 2000, position: "middle" });
            numberOfWords++;
            for (const index of currentSpacesToClear) {
              if (!spacesToClear.includes(index)) {
                spacesToClear.push(index);
              }
            }
          }
          currentSpacesToClear = [];
        }
      } else {
        diagWord = "";
        currentSpacesToClear = [];
        break;
      }
    }

    if (numberOfWords === 2) {
      doubleSFX();
    }
    if (numberOfWords === 3) {
      grandCrossSFX();
    }

    // Add the score for the current letter placement then clear the spaces if need be
    const pointsEarned = addScore(numberOfWords);
    setScore(score + pointsEarned);

    if (numberOfWords > 0) {
      clearSpaces(board, spacesToClear);
      spacesToClear = [];

      let win = false;

      if (gameHelper && level.type !== "points") {
        if (
          level.type === "word" ||
          level.type === "target" ||
          level.type === "letter"
        ) {
          win = gameHelper.checkWinCondition(wordsMade);
        } else {
          win = gameHelper.checkWinCondition(wordsMade, time);
        }
      }

      if (win) {
        // Stop the clock
        setTimerStarted(false);
        setWinCondition(true);

        // End the game
        setGameOver(true);
        return;
      }
    }

    // Check if the game will be over
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        if (board[j][i] !== "") {
          count++;
        }
      }
    }

    // Check for game over
    if (count >= 25) {
      setCheckForGameOver(true);
    }
  };

  /**
   * Place a letter in the bank or turn on highlighting to play a banked letter
   */
  const bankLetter = (): void => {
    if (timerStarted === false) {
      setTimerStarted(true);
    }

    if (bankedLetter === "") {
      if (showBombableSquares === true && nextLetter !== "bomb") {
        setBombableSquares(false);
      }
      setBankedLetter(currentLetter);
      setCurrentLetter(nextLetter);
      setNextLetter(queuedLetter);
      setQueuedLetter(letterHandler.generateRandomLetter());
      placeSFX();
      setBankedSquare(false);
    } else {
      togglePlayableSquares();
    }
  };

  /**
   * Toggle the playable squares
   */
  const togglePlayableSquares = (): void => {
    if (bankedLetter === "bomb" && currentLetter === "bomb") {
      return;
    }

    if (bankedLetter === "bomb") {
      setBombableSquares(!showBombableSquares);
    } else {
      if (currentLetter === "bomb") {
        setBombableSquares(!showBombableSquares);
      }
      setPlayableSquares(!showPlayableSquares);
    }
  };

  /**
   * Set all state items back to default settings
   */
  const restartGame = (): void => {
    setScore(0);
    setBankedLetter("");
    setBombableSquares(false);
    setBankedSquare(false);
    setPlayableSquares(false);
    setTimerStarted(false);
    setTime({ minutes: 0, seconds: 0 });
    setBoard([
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
    ]);
    setGameOver(false);
    let letters = generateStarterLetters();
    setCurrentLetter(letters.curr);
    setNextLetter(letters.next);
    setQueuedLetter(letters.queue);
  };

  const getLevelScore = async () => {
    const body = {
      username: userRecord.username,
    };

    const score = await api.post(
      `level/getLevelScore/${level.level}`,
      token,
      body
    );
    if (score) {
      setHighScore(score.score);
      setPrTime(score.time);
    }
  };

  const saveScore = async () => {
    if (
      score > highScore ||
      time.minutes < prTime.minutes ||
      (time.minutes === prTime.minutes && time.seconds < prTime.seconds)
    ) {
      let body = {
        score: score,
        username: userRecord.username,
        time: time,
      };

      await api.post(`level/setLevelScore/${level.level}`, token, body);
    }
  };

  const updateLevel = async () => {
    if (level.level === userRecord.currentLevel) {
      const body = {
        username: userRecord.username,
        levelCompleted: level.level,
      };

      await api.post("level/updateLevel", token, body);
    }
  };

  // Show the modal when the game is over
  useEffect(() => {
    if (gameOver) {
      setTimerStarted(false);

      if (winCondition) {
        saveScore();
        updateLevel();
      }

      history.push("/gameover", {
        winCondition: winCondition,
        score: score,
        highScore: highScore,
        time: time,
        prTime: prTime,
        redirect: `/game/level/${level.level}`,
      });
    }
  }, [gameOver, winCondition]);

  // Check for highlighting depending on the current letter
  useEffect(() => {
    if (checkForGameOver) {
      if (bankedLetter === "bomb" || currentLetter === "bomb") {
        setBombableSquares(true);
      } else if (bankedLetter === "") {
        setBankedSquare(true);
      } else {
        setBankedSquare(false);
        setBombableSquares(false);
        setGameOver(true);
      }
      setCheckForGameOver(false);
    } else {
      if (currentLetter === "bomb") {
        if (isEmpty(board)) {
          // No place user could put a bomb
          setCurrentLetter(nextLetter);
          setNextLetter(queuedLetter);
          setQueuedLetter(letterHandler.generateRandomLetter());
        } else {
          setBombableSquares(true);
        }
      } else {
        setBombableSquares(false);
      }
    }
  }, [currentLetter, checkForGameOver]);

  // Perform a clock tick so long as the timerStarted varible is true
  useEffect(() => {
    const interval = setInterval(() => {
      if (timerStarted) {
        if (time.seconds === 60) {
          time.seconds = 0;
          setTime({ minutes: time.minutes++, seconds: time.seconds });
        } else {
          setTime({ minutes: time.minutes, seconds: time.seconds++ });
        }
      }

      if (gameHelper) {
        if (gameHelper.timeLimit) {
          if (
            sameTime(time, gameHelper.timeLimit) ||
            isFasterTime(gameHelper.timeLimit, time)
          ) {
            // Time over
            // Stop the clock
            setTimerStarted(false);

            // End the game
            setGameOver(true);
          }
        }

        if (gameHelper.survivalTime && !gameHelper.secondaryTarget) {
          if (
            sameTime(gameHelper.survivalTime, time) ||
            isFasterTime(gameHelper.survivalTime, time)
          ) {
            // Player has survived the required time and has no specific words they should have made
            setWinCondition(true);

            // Stop the clock
            setTimerStarted(false);

            // End the game
            setGameOver(true);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timerStarted]);

  useEffect(() => {
    if (timerStarted && !showBankedSquare) {
      checkBoard();
    }
  }, [showBankedSquare]);

  useEffect(() => {
    if (level.type === "points" && gameHelper) {
      let win = gameHelper.checkWinCondition(score);
      if (win) {
        // Stop the clock
        setTimerStarted(false);
        setWinCondition(true);

        // End the game
        setGameOver(true);
        return;
      }
    }
  }, [score]);

  // Set the level helper
  useEffect(() => {
    switch (level.type) {
      case "word":
        setGameHelper(new WordHelper(level.param, level.param2));
        break;

      case "target":
        setGameHelper(new TargetHelper(level.param, level.param2));
        break;

      case "time":
        setGameHelper(new TimeHelper(level.param, level.param2));
        break;

      case "survival":
        setGameHelper(new SurvivalHelper(level.param, level.param2));
        break;

      case "points":
        setGameHelper(new PointsHelper(level.param));
        break;

      case "letter":
        setGameHelper(new LetterHelper(level.param));
        break;

      default:
      // Do something and redirect
    }
  }, [level]);

  // Set the level helper
  useEffect(() => {
    let letters = generateStarterLetters();
    setCurrentLetter(letters.curr);
    setNextLetter(letters.next);
    setQueuedLetter(letters.queue);
    getLevelScore();
  }, []);

  // When page is loaded start the game
  useIonViewDidEnter(() => {
    let letters = generateStarterLetters();
    setCurrentLetter(letters.curr);
    setNextLetter(letters.next);
    setQueuedLetter(letters.queue);
    getLevelScore();
  });

  // When user tries to leave mid game, confirm the decison
  useIonViewWillLeave(() => {});

  // When user leaves the page load destroy the state.
  useIonViewDidLeave(() => {
    restartGame();
  });

  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol>
            <IonText className="ion-text-center">
              <h1>{level.description}</h1>
            </IonText>
          </IonCol>
        </IonRow>

        {/* Display the current, next, queued, and banked letters */}
        <LetterBoard
          currentLetter={currentLetter}
          nextLetter={nextLetter}
          queuedLetter={queuedLetter}
          bankedLetter={bankedLetter}
          bankLetter={bankLetter}
          showBankedSquare={showBankedSquare}
        />

        <br />

        {/* Display the game board by iterating over the rows and columns and mapping the values */}
        <GameBoard
          board={board}
          placeLetter={placeLetter}
          showBombableSquares={showBombableSquares}
          showPlayableSquares={showPlayableSquares}
        />

        <br />

        {/* Display the score and highscore */}
        <ScoreBoard
          score={score}
          highScore={highScore}
          time={time}
          prTime={prTime}
        />
      </IonGrid>
    </IonContent>
  );
};

interface LevelProps {
  level: {
    title: string;
    description: string;
    type: string;
    param: string;
    param2: string;
    level: Number;
  };
  userRecord: any;
  cognitoUser: any;
}

import React from "react";
import { IonCol, IonRow } from "@ionic/react";
import { v4 as uuidv4 } from "uuid";

export const GameBoard: React.FC<BoardProps> = (props: BoardProps) => {
  return (
    <>
      {props.board.map((row: string[], i: number) => (
        <IonRow class="ion-justify-content-center" key={uuidv4()}>
          {row.map((col, j) => (
            <IonCol
              data-testid="game-square"
              className={`square ${
                (props.showPlayableSquares && props.board[i][j] === "") ||
                (props.showBombableSquares && props.board[i][j] !== "")
                  ? "highlighted"
                  : ""
              }`}
              size="2"
              key={uuidv4()}
              onClick={() => {
                props.placeLetter(i, j);
              }}
            >
              {props.board[i][j]}
            </IonCol>
          ))}
        </IonRow>
      ))}
    </>
  );
};

interface BoardProps {
  board: string[][];
  showPlayableSquares: boolean;
  showBombableSquares: boolean;
  placeLetter: Function;
}

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { LevelSelection } from "../../components/LevelSelection/LevelSelection";
import { getUserInfo } from "../../utils/userHelper/userHelper";

const Story: React.FC<{ user: any }> = (props: { user: any }) => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      const user = await getUserInfo(
        props.user.username,
        props.user.getSignInUserSession().getIdToken().getJwtToken()
      );
      setUser(user);
    }

    fetchData();
  }, [props.user]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Story Mode</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Story Mode</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LevelSelection userRecord={user} cognitoUser={props.user} />
      </IonContent>
    </IonPage>
  );
};

export default Story;

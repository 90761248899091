import React from "react";
import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from "@ionic/react";

export const GameSelection: React.FC = () => {
  return (
    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Word of the day</IonCardTitle>
          <IonCardSubtitle>
            Race to see how fast you can create the word of the day
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton routerLink="game/wordOfDay">Play</IonButton>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Story Mode</IonCardTitle>
          <IonCardSubtitle>
            Complete challenges in The Grand Cross!
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton routerLink="story">Play</IonButton>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Zen Mode</IonCardTitle>
          <IonCardSubtitle>
            Survive as long as you can and reach a new high score!
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton routerLink="game/zen">Play</IonButton>
        </IonCardContent>
      </IonCard>
    </IonContent>
  );
};

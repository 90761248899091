/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import api from "../../utils/apiHelper/apiHelper";

const Leaderboards: React.FC<{ user: any }> = (props: { user: any }) => {
  const [scores, setScores] = useState<Score[]>([]);
  const [leaderboard, setLeaderboard] = useState("zen");
  const [level, setLevel] = useState(1);
  const [wodDate, setWodDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [levels, setLevels] = useState<Level[]>([]);
  const token = props.user.getSignInUserSession().getIdToken().getJwtToken();

  const getLeaderboard = async (endpoint: string) => {
    let uri = "";

    switch (endpoint) {
      case "zen":
        uri = "leaderboards/zen";
        break;

      case "wod":
        uri = `leaderboards/wod/${wodDate}`;
        break;

      case "level":
        uri = `leaderboards/level/${level}`;
        break;

      default:
        uri = "leaderboards/zen";
    }

    const scores = await api.get(uri, token);

    setScores(scores);
    setLeaderboard(endpoint);

    setLoading(false);
  };

  const getLevelCount = async () => {
    const levels = await api.get("level/getLevels", token);
    setLevels(levels);
  };

  useEffect(() => {
    getLeaderboard("zen");
    getLevelCount();
  }, []);

  useEffect(() => {
    getLeaderboard(leaderboard);
  }, [wodDate, leaderboard, level]);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Leaderboards</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Leaderboards</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol size="10">
                  <IonRow>
                    <IonCol size="12">
                      <IonSegment
                        onIonChange={(e) => {
                          if (e.detail.value === undefined) {
                            return;
                          }
                          getLeaderboard(e.detail.value);
                        }}
                      >
                        <IonSegmentButton value="zen">
                          <IonLabel>Zen</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton data-testid="wod-button" value="wod">
                          <IonLabel>Word of Day</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton
                          data-testid="level-button"
                          value="level"
                        >
                          <IonLabel>Levels</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      {leaderboard === "level" ? (
                        <IonRow className="ion-justify-content-center">
                          <IonCol size="4">
                            <IonItem>
                              <IonSelect
                                onIonChange={(e) =>
                                  setLevel(parseInt(e.detail.value))
                                }
                                value={level}
                              >
                                {levels.map((level) => (
                                  <IonSelectOption
                                    key={uuidv4()}
                                    value={level.level}
                                  >
                                    {level.level}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      ) : leaderboard === "wod" ? (
                        <>
                          <IonRow>
                            <IonCol size="12">
                              <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                              <IonModal keepContentsMounted={true}>
                                <IonDatetime
                                  presentation="date"
                                  onIonChange={(e) => {
                                    const date = String(e.detail.value);
                                    setWodDate(
                                      new Date(date).toISOString().split("T")[0]
                                    );
                                  }}
                                  id="datetime"
                                ></IonDatetime>
                              </IonModal>
                            </IonCol>
                          </IonRow>
                        </>
                      ) : null}
                      <IonList>
                        {scores ? (
                          scores.map((record: Score, index) => (
                            <IonItem key={uuidv4()}>
                              <IonLabel>
                                {index + 1} {record.username}
                              </IonLabel>
                              <IonNote slot="end">
                                {record.time.minutes}:{" "}
                                {record.time.seconds < 10
                                  ? `0${record.time.seconds}`
                                  : record.time.seconds}
                              </IonNote>
                              <IonNote slot="end">{record.score}</IonNote>
                            </IonItem>
                          ))
                        ) : (
                          <IonItem>{message}</IonItem>
                        )}
                      </IonList>
                      <IonLoading
                        isOpen={loading}
                        message={"Fetching the leaderboard..."}
                      />
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Leaderboards;

interface Score {
  time: {
    minutes: Number;
    seconds: Number;
  };
  score: Number;
  userId: String;
  username: String;
}

interface Level {
  title: string;
  description: string;
  type: string;
  param: string;
  param2: string;
  level: number;
}

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { WodGame } from "../../components/WodGame/WodGame";
import { getUserInfo } from "../../utils/userHelper/userHelper";

const WordOfDay: React.FC<any> = (props: any) => {
  const [user, setUser] = useState<any>();
  const cognitoUser = props.user;

  useEffect(() => {
    async function fetchData() {
      const user = await getUserInfo(
        props.user.username,
        props.user.getSignInUserSession().getIdToken().getJwtToken()
      );
      setUser(user);
    }

    fetchData();
  }, [props.user]);

  if (user && cognitoUser) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Word Of The Day</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <WodGame userRecord={user} cognitoUser={cognitoUser} />
        </IonContent>
      </IonPage>
    );
  }

  return <IonLoading isOpen={true} message={"Loading the game"} />;
};

export default WordOfDay;

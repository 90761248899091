/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
} from "@ionic/react";

import "./LevelSelection.css";
import { ribbonOutline, ribbonSharp } from "ionicons/icons";
import api from "../../utils/apiHelper/apiHelper";

export const LevelSelection: React.FC<LevelSelectionProps> = (
  props: LevelSelectionProps
) => {
  const [levels, setLevels] = useState([]);
  const user = props.userRecord;
  const cognitoUser = props.cognitoUser;
  const token = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();

  const getLevels = async () => {
    const levels = await api.get("level/getLevels", token);
    setLevels(levels);
  };

  useEffect(() => {
    if (user && cognitoUser) {
      getLevels();
    }
  }, [user, cognitoUser]);

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        {levels && user ? (
          levels.map((level: Level, index) => (
            <IonCol size="10" key={index}>
              <IonCard
                routerLink={`game/level/${level.level}`}
                className={
                  level.level < user.currentLevel
                    ? "level-completed ion-text-center"
                    : "level-selection ion-text-center"
                }
                disabled={level.level > user.currentLevel}
              >
                <IonCardContent>
                  <h1>{level.level}</h1>
                  <h2>{level.title}</h2>
                  {level.level < user.currentLevel ? (
                    <IonIcon ios={ribbonOutline} md={ribbonSharp} />
                  ) : null}
                </IonCardContent>
              </IonCard>
            </IonCol>
          ))
        ) : (
          <IonCol size="12">
            <IonText>
              <h2>Loading...</h2>
            </IonText>
          </IonCol>
        )}
      </IonRow>
      <IonRow>{/* Buffer zone for ads */}</IonRow>
    </IonGrid>
  );
};

interface Level {
  title: string;
  description: string;
  type: string;
  param: string;
  param2: string;
  level: number;
}

interface LevelSelectionProps {
  userRecord: any;
  cognitoUser: any;
}

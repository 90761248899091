import React from "react";
import { IonCol, IonRow, IonIcon } from "@ionic/react";

export const LetterBoard: React.FC<LetterBoardProps> = (
  props: LetterBoardProps
) => {
  return (
    <IonRow class="ion-justify-content-center">
      <IonCol className="square" size="2">
        <IonRow className="ion-justify-content-center">
          <small
            style={{
              fontSize: "small",
              position: "absolute",
              top: -2,
            }}
          >
            CURRENT
          </small>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-padding-top">
          {props.currentLetter === "bomb" ? (
            <IonIcon src="./assets/bomb.svg"></IonIcon>
          ) : (
            props.currentLetter
          )}
        </IonRow>
      </IonCol>
      <IonCol className="square" size="2">
        <IonRow className="ion-justify-content-center">
          <small
            style={{
              fontSize: "small",
              position: "absolute",
              top: -2,
            }}
          >
            NEXT
          </small>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-padding-top">
          {props.nextLetter === "bomb" ? (
            <IonIcon src="./assets/bomb.svg"></IonIcon>
          ) : (
            props.nextLetter
          )}
        </IonRow>
      </IonCol>
      <IonCol className="square" size="2">
        <IonRow className="ion-justify-content-center">
          <small
            style={{
              fontSize: "small",
              position: "absolute",
              top: -2,
            }}
          >
            QUEUED
          </small>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-padding-top">
          {props.queuedLetter === "bomb" ? (
            <IonIcon src="./assets/bomb.svg"></IonIcon>
          ) : (
            props.queuedLetter
          )}
        </IonRow>
      </IonCol>
      <IonCol size="2"> </IonCol>
      <IonCol
        data-testid="banked-square"
        onClick={() => {
          props.bankLetter();
        }}
        className={`square  ${props.showBankedSquare ? "highlighted" : null}`}
        size="2"
        id="bank-square"
      >
        <IonRow className="ion-justify-content-center">
          <small
            style={{
              fontSize: "small",
              position: "absolute",
              top: -2,
            }}
          >
            BANK
          </small>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          {props.bankedLetter === "bomb" ? (
            <IonIcon
              className="ion-padding-top"
              src="./assets/bomb.svg"
            ></IonIcon>
          ) : (
            props.bankedLetter
          )}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

interface LetterBoardProps {
  currentLetter: string;
  nextLetter: string;
  queuedLetter: string;
  bankedLetter: string;
  showBankedSquare: boolean;
  bankLetter: Function;
}

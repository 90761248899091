import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { GameSelection } from "../../components/GameSelection/GameSelection";
import "./Play.css";

const Play: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Play</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <GameSelection />
      </IonContent>
    </IonPage>
  );
};

export default Play;

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";

import { useLocation } from "react-router-dom";
import {
  logOutOutline,
  logOutSharp,
  appsSharp,
  appsOutline,
  trophyOutline,
  // settingsOutline,
  // settingsSharp,
  // medalOutline,
  // medalSharp,
  trophySharp,
  helpCircleOutline,
  helpCircleSharp,
  settingsOutline,
  settingsSharp,
  // bagOutline,
  // bagSharp,
} from "ionicons/icons";
import "./Menu.css";
import { useEffect, useState } from "react";
import { getUserInfo } from "../../utils/userHelper/userHelper";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Play",
    url: "/Play",
    iosIcon: appsOutline,
    mdIcon: appsSharp,
  },
  // {
  //   title: "Achievements",
  //   url: "/Achievements",
  //   iosIcon: medalOutline,
  //   mdIcon: medalSharp,
  // },
  {
    title: "Leaderboards",
    url: "/leaderboards",
    iosIcon: trophyOutline,
    mdIcon: trophySharp,
  },
  // {
  //   title: "Shop",
  //   url: "/Shop",
  //   iosIcon: bagOutline,
  //   mdIcon: bagSharp,
  // },
  {
    title: "Settings",
    url: "/Settings",
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
  },
  {
    title: "Help",
    url: "/help",
    iosIcon: helpCircleOutline,
    mdIcon: helpCircleSharp,
  },
];

const Menu: React.FC<any> = (props: any) => {
  const location = useLocation();

  const [user, setUser] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      const user = await getUserInfo(
        props.user.username,
        props.user.getSignInUserSession().getIdToken().getJwtToken()
      );
      setUser(user);
    }

    fetchData();
  }, [props.user]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>The Grand Cross</IonListHeader>
          <IonNote>{user?.username || ""}</IonNote>
          <IonMenuToggle autoHide={false}>
            {appPages.map((appPage) => {
              return (
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  key={uuidv4()}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              );
            })}
            <IonItem
              lines="none"
              detail={false}
              onClick={() => props.signOut()}
            >
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

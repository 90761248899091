import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonListHeader,
  IonButtons,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import api from "../../utils/apiHelper/apiHelper";
import { AccountSettings } from "@aws-amplify/ui-react";

export const SettingsList: React.FC<SettingsProps> = (props: any) => {
  const [settings, setSettings] = useState([]);
  const token = props.cognitoUser
    .getSignInUserSession()
    ?.getIdToken()
    .getJwtToken();

  const getSettings = async () => {
    const settings = await api.get("api/settings", token);
    setSettings(settings);
  };

  const modal = useRef<HTMLIonModalElement>(null);

  const handleDelete = async () => {
    const body = {
      username: props.cognitoUser.username,
    };
    const result = await api.delete("api/deleteAccount", token, body);
    if (result.deletedCount && result.deletedCount === 1) {
      modal.current?.dismiss();
      await Auth.deleteUser();
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="10">
            {/*-- List of all settings available in the game --*/}
            <IonList>
              {settings.map((settingItem: any, index: number) => (
                <IonItem data-testid="settings-item" key={index}>
                  <IonLabel>{settingItem.title}</IonLabel>
                  <IonToggle slot="end"></IonToggle>
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
        <IonRow className="ion-padding"></IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol size="10">
            <IonList>
              <IonListHeader>
                <IonLabel>Account Settings</IonLabel>
              </IonListHeader>
              <IonItem data-testid="settings-item">
                <IonLabel>Delete Account</IonLabel>
                <IonButton id="open-modal" slot="end" color="danger">
                  Delete
                </IonButton>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonModal ref={modal} trigger="open-modal">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Are you sure?</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <AccountSettings.DeleteUser handleDelete={handleDelete} />
        </IonContent>
      </IonModal>
    </IonContent>
  );
};

interface SettingsProps {
  cognitoUser: CognitoUser;
  signOut: Function | undefined;
}

import api from "../apiHelper/apiHelper";

const getUserInfo = async (username: string | undefined, jwt: string) => {
  if (!username || !jwt) {
    return {};
  }

  const body = {
    username: username,
  };

  try {
    const result = await api.post("api/getUserInfo", jwt, body);
    return result;
  } catch (error) {
    return {};
  }
};

export { getUserInfo };

import React from "react";
import { IonCol, IonRow } from "@ionic/react";
import "./ScoreBoard.css";

export const ScoreBoard: React.FC<ScoreBoardProps> = (
  props: ScoreBoardProps
) => {
  return (
    <IonRow class="ion-justify-content-center">
      <IonCol className="score-section" size="4">
        Score: {props.score}
      </IonCol>
      <IonCol className="score-section " size="4">
        Timer: {props.time.minutes}:
        {props.time.seconds < 10
          ? `0${props.time.seconds}`
          : props.time.seconds}
      </IonCol>
    </IonRow>
  );
};

interface ScoreBoardProps {
  score: number;
  highScore: number;
  time: { minutes: number; seconds: number };
  prTime: { minutes: number; seconds: number };
}

const api = {
  get: async (endpoint: string, token: string) => {
    let result;

    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        Authorization: token,
        Accept: "application/json",
      },
    });

    if (!response) {
      return {};
    }

    if (response.status === 200) {
      result = await response.json();
    } else {
      result = {};
    }
    return result;
  },

  post: async (endpoint: string, token: string, body: any) => {
    let result;

    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response) {
      return {};
    }

    if (response.status === 200) {
      result = await response.json();
    } else {
      result = {};
    }
    return result;
  },

  delete: async (endpoint: string, token: string, body: any) => {
    let result;

    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      method: "DELETE",
      body: JSON.stringify(body),
    });

    if (!response) {
      return {};
    }

    if (response.status === 200) {
      result = await response.json();
    } else {
      result = {};
    }
    return result;
  },
};

export default api;

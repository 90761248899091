import { LetterHandler } from "./../letterHandler/letterHandler";

const letterHandler: LetterHandler = new LetterHandler();
const madeWordSFX = new Audio("./assets/GrandCross_made_word.mp3");
const doubleSFX = new Audio("./assets/GrandCross_double.mp3");
const grandCrossSFX = new Audio("./assets/GrandCross_grandcross.mp3");

/**
 * Add points to score
 * @param {number} indicator - A number representing how many words you created in order to calculate score
 */
const addScore = (indicator: number): number => {
  switch (indicator) {
    case 0: {
      return 50;
    }

    case 1: {
      return 500;
    }

    case 2: {
      return 2500;
    }

    case 3: {
      return 10000;
    }

    default: {
      return 50;
    }
  }
};

/**
 * Clear spaces on the board, used after a word is confirmed
 */
const clearSpaces = (
  board: string[][],
  spacesToClear: number[][]
): string[][] => {
  try {
    spacesToClear.forEach((index: number[]) => {
      board[index[0]][index[1]] = "";
    });
  } catch (error) {
    // TODO: Do something?
  }

  return board;
};

/**
 * Generate the starter letters for the game.
 */
const generateStarterLetters = () => {
  let curr;

  do {
    curr = letterHandler.generateRandomLetter();
  } while (curr === "bomb");

  return {
    curr: curr,
    next: letterHandler.generateRandomLetter(),
    queue: letterHandler.generateRandomLetter(),
  };
};

/**
 * Place a letter on the board in the designated position if avaliable for play
 * @param {string[][]} board - A five by five board used to parse for words
 * @return {string[]} word - string array of words
 * @return {number[][]} spacesToClear - The spaces to clear on the board after acknowledging a word
 */
const readBoard = (board: string[][]) => {
  let spacesToClear: number[][] = [];
  let words = [];
  let currentSpacesToClear: number[][] = [];
  let diagWord = "";

  // Check the board horizontally
  for (let i = 0; i < 5; i++) {
    let word = "";
    for (let j = 0; j < 5; j++) {
      if (board[i][j] !== "") {
        currentSpacesToClear.push([i, j]);
        word += board[i][j];
        if (word.length === 5) {
          if (letterHandler.checkDictionary(word.toLowerCase())) {
            words.push(word.toLowerCase());
            madeWordSFX.play();
            for (const index of currentSpacesToClear) {
              spacesToClear.push(index);
            }
          }
          currentSpacesToClear = [];
        }
      } else {
        currentSpacesToClear = [];
        break;
      }
    }
  }

  // Check the board vertically
  for (let i = 0; i < 5; i++) {
    let word = "";
    for (let j = 0; j < 5; j++) {
      if (board[j][i] !== "") {
        currentSpacesToClear.push([j, i]);
        word += board[j][i];
        if (word.length === 5) {
          if (letterHandler.checkDictionary(word.toLowerCase())) {
            words.push(word.toLowerCase());
            madeWordSFX.play();
            for (const index of currentSpacesToClear) {
              spacesToClear.push(index);
            }
          }
          currentSpacesToClear = [];
        }
      } else {
        currentSpacesToClear = [];
        break;
      }
    }
  }

  // Check the boards diagonal row
  for (let i = 0; i < 5; i++) {
    if (board[i][i] !== "") {
      currentSpacesToClear.push([i, i]);
      diagWord += board[i][i];
      if (diagWord.length === 5) {
        if (letterHandler.checkDictionary(diagWord.toLowerCase())) {
          words.push(diagWord.toLowerCase());
          madeWordSFX.play();
          for (const index of currentSpacesToClear) {
            spacesToClear.push(index);
          }
        }
        currentSpacesToClear = [];
      }
    } else {
      diagWord = "";
      currentSpacesToClear = [];
      break;
    }
  }

  if (words.length === 2) {
    doubleSFX.play();
  }
  if (words.length === 3) {
    grandCrossSFX.play();
  }

  return { words, spacesToClear };
};

const isEmpty = (board: string[][]): boolean => {
  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 5; j++) {
      if (board[i][j] !== "") {
        return false;
      }
    }
  }
  return true;
};

export { addScore, clearSpaces, generateStarterLetters, readBoard, isEmpty };

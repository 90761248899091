import { dictionary } from "../dictionary/dictionary";

export class LetterHandler {
  letterFrequencyDistribution: LetterFrequencyDistribution;

  constructor() {
    this.letterFrequencyDistribution = {
      A: 8.497,
      B: 10.569,
      C: 15.108,
      D: 18.492,
      E: 30.102,
      F: 31.914,
      G: 34.385,
      H: 37.388,
      I: 44.933,
      J: 45.13,
      K: 46.232,
      L: 51.721,
      M: 54.734,
      N: 61.388,
      O: 68.552,
      P: 71.719,
      Q: 71.915,
      R: 79.496,
      S: 85.231,
      T: 92.182,
      U: 95.813,
      V: 96.82,
      W: 98.11,
      X: 98.4,
      Y: 100.178,
      Z: 101.0,
    };
  }

  generateRandomLetter = function (this: LetterHandler): string {
    let randomNumber = Math.random() * (101 - 0) + 0 / 1000;
    let bombGenerator = Math.round(Math.random() * 15);
    let marker = 0;
    let letter = "";

    if (bombGenerator <= 1) {
      letter = "bomb";
    } else {
      randomNumber = parseFloat(randomNumber.toFixed(3));

      for (const key in this.letterFrequencyDistribution) {
        if (
          randomNumber >= marker &&
          randomNumber < this.letterFrequencyDistribution[key]
        ) {
          letter = key;
          break;
        } else {
          marker = this.letterFrequencyDistribution[key];
        }
      }
    }

    return letter;
  };

  checkDictionary = function (word: string): boolean {
    return dictionary.includes(word);
  };
}

interface LetterFrequencyDistribution {
  [A: string]: number;
  B: number;
  C: number;
  D: number;
  E: number;
  F: number;
  G: number;
  H: number;
  I: number;
  J: number;
  K: number;
  L: number;
  M: number;
  N: number;
  O: number;
  P: number;
  Q: number;
  R: number;
  S: number;
  T: number;
  U: number;
  V: number;
  W: number;
  X: number;
  Y: number;
  Z: number;
}

import {
  convertStringToTime,
  isFasterTime,
  sameTime,
} from "../timeHelper/timerHelper";

interface Time {
  minutes: number;
  seconds: number;
}

class WordHelper {
  wordsMade = 0;
  stipulation: string;
  goalNumberOfWords: number;

  constructor(numberOfWords: string, stipulation: string) {
    this.stipulation = stipulation;
    this.goalNumberOfWords = parseInt(numberOfWords);
  }

  checkWinCondition = function (this: WordHelper, words: string[]): boolean {
    if (this.stipulation === "triple" && words.length === 3) {
      this.wordsMade++;
      return this.wordsMade >= this.goalNumberOfWords;
    }

    if (this.stipulation === "double" && words.length >= 2) {
      this.wordsMade++;
      return this.wordsMade >= this.goalNumberOfWords;
    }

    if (!this.stipulation && words.length >= 1) {
      this.wordsMade++;
      return this.wordsMade >= this.goalNumberOfWords;
    }

    return false;
  };
}

class TargetHelper {
  stipulation: string;
  target: string;
  secondaryTarget = "";
  numberTarget: number = 0;
  wordsMadeArray: string[] = [];
  wordTracker: any = {};

  constructor(target: string, stipulation: string | null) {
    this.stipulation = stipulation || "";
    this.target = target;
    if (isNaN(parseInt(this.stipulation))) {
      this.secondaryTarget = this.stipulation;
    } else {
      this.numberTarget = parseInt(this.stipulation);
    }
  }

  checkWinCondition = function (this: TargetHelper, words: string[]): boolean {
    if (this.secondaryTarget || this.stipulation === "") {
      if (words.includes(this.target) || words.includes(this.secondaryTarget)) {
        return true;
      }
    }

    if (this.numberTarget > 0) {
      words.forEach((word) => {
        if (this.wordTracker[word]) {
          this.wordTracker[word]++;
        } else {
          this.wordTracker[word] = 1;
        }
      });

      for (const word in this.wordTracker) {
        if (this.target === "?") {
          // We only need to check that any word is in the words made Array the target number of times
          if (this.wordTracker[word] >= this.numberTarget) {
            return true;
          }
        } else {
          if (
            word === this.target &&
            this.wordTracker[word] >= this.numberTarget
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };
}

class TimeHelper {
  secondaryTarget = "";
  numberTarget: number = 0;
  wordsMade = 0;
  timeLimit: Time;

  constructor(timeLimit: string, target: string) {
    if (!timeLimit || !target) {
      throw new Error("Missing all parameters to create Time Helper");
    }
    this.timeLimit = convertStringToTime(timeLimit);
    if (isNaN(parseInt(target))) {
      this.secondaryTarget = target;
    } else {
      this.numberTarget = parseInt(target);
    }
  }

  checkWinCondition = function (
    this: TimeHelper,
    words: string[],
    currentTime: Time
  ): boolean {
    if (!isFasterTime(currentTime, this.timeLimit)) {
      return false;
    }

    if (this.secondaryTarget) {
      if (words.includes(this.secondaryTarget)) {
        return true;
      }
    }

    if (this.numberTarget) {
      words.forEach(() => {
        this.wordsMade++;
      });

      if (this.wordsMade >= this.numberTarget) {
        return true;
      }
    }

    return false;
  };
}

class SurvivalHelper {
  secondaryTarget = "";
  wordsMadeArray: string[] = [];
  wordsMade = 0;
  survivalTime;

  constructor(survivalTimeLength: string, target: string | null) {
    if (!survivalTimeLength) {
      throw new Error("Missing all parameters to create Survival Helper");
    }
    this.survivalTime = convertStringToTime(survivalTimeLength);
    if (target) {
      if (isNaN(parseInt(target))) {
        this.secondaryTarget = target;
      }
    }
  }

  checkWinCondition = function (
    this: SurvivalHelper,
    words: string[],
    currentTime: Time
  ): boolean {
    words.forEach((word) => {
      this.wordsMadeArray.push(word);
    });

    if (
      sameTime(this.survivalTime, currentTime) ||
      isFasterTime(this.survivalTime, currentTime)
    ) {
      if (this.secondaryTarget) {
        if (!this.wordsMadeArray.includes(this.secondaryTarget)) {
          return false;
        }
      }

      return true;
    }

    return false;
  };
}

class PointsHelper {
  stipulation: number;

  constructor(stipulation: string) {
    if (!stipulation) {
      throw new Error("Missing parameters to create Points Helper");
    }
    this.stipulation = parseInt(stipulation);
  }

  checkWinCondition = function (this: PointsHelper, points: number): boolean {
    return points >= this.stipulation;
  };
}

class LetterHelper {
  stipulation: string;

  constructor(stipulation: string) {
    if (!stipulation) {
      throw new Error("Missing parameters to create Letter Helper");
    }
    this.stipulation = stipulation;
  }

  checkWinCondition = function (this: LetterHelper, words: string[]): boolean {
    let win = false;

    words.forEach((word) => {
      if (word[0].toLowerCase() === this.stipulation) {
        win = true;
      }
    });
    return win;
  };
}

export {
  WordHelper,
  TargetHelper,
  TimeHelper,
  SurvivalHelper,
  PointsHelper,
  LetterHelper,
};

/**
 * Determine if the first time is faster than the second
 * @param {Time} time - Object containing minutes and seconds properties. First time to compare
 * @param {Time} secondTime - Object containing minutes and seconds properties. Second time to compare
 * @returns {boolean} Is time less than secondTime
 */
const isFasterTime = (time: Time, secondTime: Time): boolean => {
  if (!time) {
    return false;
  }

  if (time && !secondTime) {
    return true;
  }

  if (
    secondTime.minutes === 0 &&
    secondTime.seconds === 0 &&
    (time.minutes !== 0, time.seconds !== 0)
  ) {
    return true;
  }

  if (time.minutes < secondTime.minutes) {
    return true;
  }

  if (
    time.minutes === secondTime.minutes &&
    time.seconds < secondTime.seconds
  ) {
    return true;
  }

  return false;
};

/**
 * Determine if the first time is the same as the second
 * @param {Time} time - Object containing minutes and seconds properties. Represents the users current time
 * @param {Time} secondTime - Object containing minutes and seconds properties. Represents the users best time
 * @returns {boolean} Is time less than secondTime
 */
const sameTime = (time: Time, secondTime: Time): boolean => {
  if (!time || !secondTime) {
    return false;
  }

  if (
    time.minutes === secondTime.minutes &&
    time.seconds === secondTime.seconds
  ) {
    return true;
  }

  return false;
};

/**
 * Convert float string to a Time object
 * @param {string} string - string representing a number of minutes. Possible to have .5 at the end meaning :30
 *
 * @returns {Time} A time object representing the Time equivilant in minutes and seconds
 */
const convertStringToTime = (string: string): Time => {
  const number = Number(string);
  let time = { minutes: 0, seconds: 0 };

  // check if the passed value is a number
  if (typeof number == "number" && !isNaN(number)) {
    // check if it is integer
    if (Number.isInteger(number)) {
      time.minutes = number;
    } else {
      time.minutes = Math.floor(number);
      time.seconds = 30;
    }

    return time;
  }

  return time;
};

export { isFasterTime, sameTime, convertStringToTime };

interface Time {
  minutes: number;
  seconds: number;
}

export const modes = {
    zen: {
        title: "Zen",
        info: "Just relax and play",
        apiEndpoints: {
            getScore: 'getZenScore',
            setScore: 'setZenScore'
        }
    },
    wod: {
        title: "Word of the Day",
        info: "Try to make the word of the day",
        apiEndpoints: {
            getScore: 'getWodScore',
            setScore: 'setWodScore'
        }
    }
}
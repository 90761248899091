import React from "react";
import {
  IonContent,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { SettingsList } from "../../components/SettingsList/SettingsList";

const Settings: React.FC<{
  user: any;
  signOut: Function | undefined;
}> = (props: { user: any; signOut: Function | undefined }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SettingsList cognitoUser={props.user} signOut={props.signOut} />
      </IonContent>
    </IonPage>
  );
};

export default Settings;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  isPlatform,
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
} from "@capacitor-community/admob";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* App level CSS */
import "./App.css";

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";

import Menu from "./components/Menu/Menu";
import Play from "./pages/Play/Play";
import Leaderboards from "./pages/Leaderboards/Leaderboards";
import Zen from "./pages/Zen/Zen";
import WordOfDay from "./pages/WordOfDay/WordOfDay";
import Story from "./pages/Story/Story";
import Level from "./pages/Level/Level";
import GameOver from "./pages/GameOver/GameOver";
import {
  components,
  formFields,
} from "./components/Authenticator/Authenticator";
import Help from "./pages/Help/Help";
import Settings from "./pages/Settings/Settings";

Amplify.configure(awsExports);

setupIonicReact();

const App: React.FC = () => {
  let appAdId: string | undefined;

  const showBanner = async () => {
    const options: BannerAdOptions = {
      adId: appAdId || "",
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      isTesting: process.env.NODE_ENV === "production" ? false : true,
    };
    await AdMob.showBanner(options);
  };

  useEffect(() => {
    if (isPlatform("ios") || isPlatform("android")) {
      if (isPlatform("ios")) {
        appAdId = process.env.REACT_APP_IOS_BANNER_AD_ID || "";
      }

      if (isPlatform("android")) {
        appAdId = process.env.REACT_APP_ANDROID_BANNER_AD_ID || "";
      }
      AdMob.initialize({
        requestTrackingAuthorization: true,
      });
      showBanner();
      if (!localStorage.getItem("gc-sound-on")) {
        localStorage.setItem("gc-sound-on", "false");
      }
    }
  }, []);

  return (
    <IonApp>
      <Authenticator components={components} formFields={formFields}>
        {({ signOut, user }) => {
          return (
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu user={user} signOut={signOut} />
                <IonRouterOutlet id="main">
                  <Route path="/" exact={true}>
                    <Redirect to="/Play" />
                  </Route>
                  <Route path="/Play" exact={true}>
                    <Play />
                  </Route>
                  <Route path="/game/zen" exact={true}>
                    <Zen user={user} />
                  </Route>
                  <Route path="/game/wordOfDay" exact={true}>
                    <WordOfDay user={user} />
                  </Route>
                  <Route path="/game/level/:level" exact={true}>
                    <Level user={user} />
                  </Route>
                  <Route path="/leaderboards" exact={true}>
                    <Leaderboards user={user} />
                  </Route>
                  <Route path="/story">
                    <Story user={user} />
                  </Route>
                  <Route path="/help">
                    <Help />
                  </Route>
                  <Route path="/settings">
                    <Settings user={user} signOut={signOut} />
                  </Route>
                  <Route path="/gameover">
                    <GameOver />
                  </Route>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
          );
        }}
      </Authenticator>
    </IonApp>
  );
};

export default App;
